const styles = {
  danger: {
    bg: 'bg-red-100',
    text: 'text-red-600',
    button: 'bg-red-600'
  },
  info: {
    bg: 'bg-sky-100',
    text: 'text-sky-500',
    button: 'bg-sky-500'
  },
  success: {
    bg: 'bg-emerald-100',
    text: 'text-emerald-500',
    button: 'bg-emerald-500'
  }
}

const i18n = {
  en: {
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  uk: {
    confirm: 'ОК',
    cancel: 'Назад'
  },
  pl: {
    confirm: 'ОК',
    cancel: 'Anulować'
  }
}

const lang = (document.documentElement.lang || navigator.language).split('-')[0]

const dict = i18n[lang] || i18n.en

const turboConfirmMethod = async (message, element) => {
  return new Promise((resolve, reject) => {
    let confirmData

    try {
      confirmData = JSON.parse(message)
    } catch {
      confirmData = { message }
    }

    const confirm = confirmData.message
    const confirmMessage = confirmData.details
    const confirmStyle = styles[confirmData.style || 'danger']

    const content = `
      <div id="confirm_modal" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-75 z-40"></div>
        <div class="fixed z-40 top-0 left-0 right-0 bottom-0 overflow-y-auto" data-role="mask">
          <div class="mx-auto mt-40 mb-10 relative bg-white rounded-lg text-left shadow-xl w-full max-w-lg">
            <div class="p-6">
              <div class="flex">
                <div class="mt-4 flex-shrink-0 flex items-center justify-center rounded-full ${confirmStyle.bg} mx-0 h-10 w-10">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ${confirmStyle.text}" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4" />
                    <line x1="12" y1="19" x2="12" y2="19.01" />
                  </svg>
                </div>
                <div class="mt-3 mt-0 ml-4 text-left whitespace-normal">
                  <p class="text-lg text-gray-700" id="modal-title">${confirm}</p>
                  ${confirmMessage ? `<p class="text-gray-600" id="modal-title">${confirmMessage}</p>` : ''}
                </div>
              </div>
              <div class="mt-8 flex flex-row-reverse">
                <button data-behavior="commit" type="button" class="${confirmStyle.button} inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-0 focus:ring-0 ml-3 w-auto text-sm" style="min-width: 80px">${dict.confirm}</button>
                <button  data-behavior="cancel" type="button" class="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none mt-0 w-auto text-sm">${dict.cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `

    document.body.insertAdjacentHTML('afterbegin', content)

    const modal = document.querySelector('#confirm_modal')

    modal.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        element.removeAttribute('data-confirm-modal')
        modal.remove()
        resolve(false)
      }
    })

    modal.querySelector('[data-role="mask"]').addEventListener('click', (e) => {
      if (e.target.dataset.role === 'mask') {
        e.preventDefault()

        if (e.target.classList.contains('fixed') && !window.getSelection().toString().length) {
          element.removeAttribute('data-confirm-modal')
          modal.remove()
          resolve(false)
        }
      }
    })

    modal.querySelector("[data-behavior='cancel']").addEventListener('click', (event) => {
      event.preventDefault()

      element.removeAttribute('data-confirm-modal')
      modal.remove()
      resolve(false)
    })

    modal.querySelector("[data-behavior='commit']").addEventListener('click', (event) => {
      event.preventDefault()

      modal.remove()
      resolve(true)
    })

    modal.querySelector("[data-behavior='commit']").focus()
  })
}

export { turboConfirmMethod }
