const activeClasses = ['bg-gray-900', 'text-white', 'font-medium']
const inactiveClasses = ['text-gray-100', 'hover:bg-gray-700']

export default class extends HTMLElement {
  connectedCallback () {
    let isActiveAdded = false

    this.querySelectorAll('a, button').forEach((link) => {
      if (isActiveAdded) {
        return
      }

      if (document.location.pathname.startsWith(link.pathname) || document.location.pathname.startsWith(link.dataset.navPrefix)) {
        isActiveAdded = true

        link.classList.add(...this.activeClasses)
        link.classList.remove(...this.inactiveClasses)
      }
    })
  }

  get activeClasses () {
    return this.dataset.activeClasses?.split(' ') || activeClasses
  }

  get inactiveClasses () {
    return this.dataset.inactiveClasses?.split(' ') || inactiveClasses
  }
}
